@import 'swiper/scss';
@import 'swiper/scss/navigation';
@import 'swiper/scss/pagination';
@import 'swiper/scss/autoplay';
@import 'swiper/scss/effect-fade';

/* stylelint-disable-next-line selector-type-no-unknown */
slider {
  display: flex;
  overflow: hidden;
}

.swiper-container {
  box-sizing: content-box;
  overflow: hidden;
  padding-bottom: 30px;
  position: relative;

  .swiper-slide {
    align-items: center;
    background-color: #f7f7f7;
    display: flex;
    justify-content: center;

    a {
      line-height: 0;
    }

    img {
      height: auto;
      max-height: 100%;
      max-width: 100%;
      width: auto;
    }
  }

  &.swiper-container-horizontal {
    padding-bottom: 30px;
  }

  nav > div {
    background: none;
    background-color: #695948;
    border-radius: 50%;
    height: 38px;
    margin-top: -34px;
    opacity: 0.5;
    width: 38px;

    &:hover {
      opacity: 0.7;
    }

    &::before {
      color: #fff;
      font-family: $font-icon;
      font-size: 40px;
      font-weight: bold;
      left: 0;
      line-height: 38px;
      position: absolute;
      top: 0;
      width: 38px;
    }

    &.swiper-button-prev {
      &::before {
        content: $icon-arrow-left;
        text-indent: -1px;
      }

      &::after {
        content: unset;
      }
    }

    &.swiper-button-next {
      &::before {
        content: $icon-arrow-right;
      }

      &::after {
        content: unset;
      }
    }
  }

  .swiper-pagination-bullets {
    bottom: 6px;
    -webkit-tap-highlight-color: transparent;
  }

  .swiper-pagination-bullet {
    height: 6px;
    width: 6px;
  }

  .swiper-pagination-bullet-active {
    background: black;
    opacity: 0.6;
  }
}
