/* 作品一覧 ----------------------------------------------- */

.listing {
  display: grid;
  gap: 25px 15px;
  grid-template-columns: repeat(2, 1fr);

  &.-col1 {
    grid-template-columns: 1fr;
  }

  @media only screen and (min-width: #{$breakpoint-s + 1}) {
    &.-col3 {
      grid-template-columns: repeat(3, 1fr);
    }

    &.-col4 {
      grid-template-columns: repeat(4, 1fr);
    }

    &.-col5 {
      grid-template-columns: repeat(5, 1fr);
    }

    &.-col8 {
      grid-template-columns: repeat(8, 1fr);
    }
  }

  @media only screen and (max-width: $breakpoint-s) {
    gap: 20px 10px;

    &.-col8 {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  > li {
    overflow: hidden;
  }

  .info {
    font-size: 12px;
    padding: 0 3px;
  }

  .photo {
    margin-bottom: 8px;
    position: relative;

    a {
      aspect-ratio: 1 / 1;
      background-color: $gray100;
      display: block;
    }

    img {
      height: 100%;
      object-fit: cover;
      vertical-align: bottom;
      width: 100%;
    }

    .like-button {
      bottom: 0;
      position: absolute;
      right: 0;
    }
  }

  .name,
  .maker {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .name a {
    color: $gray900;
  }

  .maker {
    @media only screen and (max-width: $breakpoint-s) {
      display: none;
    }

    a {
      color: #746a58;
    }
  }

  .price {
    @media only screen and (max-width: $breakpoint-s) {
      font-weight: bold;
    }
  }
}
